<template>
  <b-row>
    <b-col sm="12" md="12" class="mt-1">
      <b-form @submit.prevent>
        <b-card>
          <b-card-body>
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <b-form
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.date") }}</label>
                    <flat-pickr
                      v-model="appData.date"
                      :config="{
                        dateFormat: 'd-m-Y',
                      }"
                      placeholder="DD-MM-YYYY"
                      class="form-control"
                    />
                  </b-col>

                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="amount"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.amount')"
                      :label="$t('fields.amount')"
                      :valuex="appData.amount"
                      @updatedata="
                        (val) => managefloat('amount', parseFloat(val))
                      "
                      validations="integer"
                    >
                    </inputtext>
                  </b-col>

                  <b-col cols="6">
                    <h5 class="mt-1 p-2">{{ $t("fields.frompayment") }}</h5>
                    <b-col sm="12" md="12" class="mt-1">
                      <label> {{ $t("fields.paytype") }}</label>
                      <inputselect
                        name="paytype"
                        :tooltip="
                          $t('fields.select') + ' ' + $t('fields.paytype')
                        "
                        :placeholder="
                          $t('fields.select') + ' ' + $t('fields.paytype')
                        "
                        :valuex="appData.paytype"
                        keys="key"
                        @updatedata="
                          (val) => {
                            mangepaytype(val);
                          }
                        "
                        :options="['Bank', 'Cash', 'Credit Cards']"
                        id="paytype"
                        validations="required"
                      ></inputselect>
                      <div class="mt-2">
                        <label> {{ $t("fields.paytype") }}</label>
                        <inputvselect
                          name="paytype"
                          :tooltip="
                            $t('fields.select') + ' ' + $t('fields.paytype')
                          "
                          :placeholder="
                            $t('fields.select') + ' ' + $t('fields.paytype')
                          "
                          :valuex="appData.paytypedatax"
                          :label="
                            (reldata) =>
                              labelx == 'bank'
                                ? reldata.data.bankname +
                                  ' - ' +
                                  reldata.data.accountnumber
                                : labelx == 'cash'
                                ? reldata.data.holdername +
                                  ' - ' +
                                  reldata.data.balance
                                : labelx == 'creditcards'
                                ? reldata.data.holdername +
                                  ' - ' +
                                  reldata.data.cardnumber
                                : labelx == 'paylater'
                                ? reldata.data.holdername +
                                  ' - ' +
                                  reldata.data.wallet
                                : reldata
                          "
                          keys="key"
                          @updatedata="
                            (val) => {
                              appData.paytypedatax = val ? val.id : null;
                            }
                          "
                          :options="paydatax"
                          id="paytype"
                          validations="required"
                        ></inputvselect>
                      </div>
                    </b-col>
                  </b-col>

                  <!-- ////////To Pay type//////// -->
                  <b-col cols="6">
                    <h5 class="mt-1 p-2">{{ $t("fields.topayment") }}</h5>
                    <b-col sm="12" md="12" class="mt-1">
                      <label> {{ $t("fields.topaytype") }}</label>
                      <inputselect
                        name="topaytype"
                        :tooltip="
                          $t('fields.select') + ' ' + $t('fields.topaytype')
                        "
                        :placeholder="
                          $t('fields.select') + ' ' + $t('fields.topaytype')
                        "
                        :valuex="appData.topaytype"
                        keys="key"
                        @updatedata="
                          (val) => {
                            mangetopaytype(val);
                          }
                        "
                        :options="['Bank', 'Cash', 'Wallet', 'Paylater','Credit Cards','Loan Accounts']"
                        id="topaytype"
                        validations="required"
                      ></inputselect>
                      <div class="mt-2">
                        <label> {{ $t("fields.topaytype") }}</label>
                        <inputvselect
                          name="topaytype"
                          :tooltip="
                            $t('fields.select') + ' ' + $t('fields.topaytype')
                          "
                          :placeholder="
                            $t('fields.select') + ' ' + $t('fields.topaytype')
                          "
                          :valuex="appData.topaytypedatax"
                          :label="
                            (reldata) =>
                              labelto == 'bank'
                                ? reldata.data.bankname +
                                  ' - ' +
                                  reldata.data.accountnumber
                                : labelto == 'cash'
                                ? reldata.data.holdername +
                                  ' - ' +
                                  reldata.data.balance
                                : labelto == 'wallet'
                                ? reldata.data.holdername +
                                  ' - ' +
                                  reldata.data.wallet
                                : labelto == 'paylater'
                                ? reldata.data.holdername +
                                  ' - ' +
                                  reldata.data.wallet
                                : labelto == 'creditcards'
                                ? reldata.data.holdername +
                                  ' - ' +
                                  reldata.data.cardnumber
                                : labelto == 'loanaccounts'
                                ? reldata.data.holdername +
                                  ' - ' +
                                  reldata.data.loanid
                                : reldata
                          "
                          keys="key"
                          @updatedata="
                            (val) => {
                              appData.topaytypedatax = val ? val.id : null;
                            }
                          "
                          :options="topaydatax"
                          id="topaytype"
                          validations="required"
                        ></inputvselect>
                      </div>
                    </b-col>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="description"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.description')
                      "
                      :label="$t('fields.description')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.description')
                      "
                      :valuex="appData.description"
                      @updatedata="(val) => (appData.description = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="notes"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.notes')"
                      :label="$t('fields.notes')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.notes')
                      "
                      :valuex="appData.notes"
                      @updatedata="(val) => (appData.notes = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" v-if="edittype == 0">
                    <h5 class="mt-2">{{ $t("fields.fileupload") }}</h5>
                    <b-row>
                      <b-col sm="12" md="6" class="mt-1" lg="6">
                        <validation-provider
                          #default="validationContext"
                          name="Upload File"
                          rules=""
                        >
                          <b-form-group
                            :label="$t('fields.uploadfile')"
                            label-for="fileinput"
                          >
                            <b-form-file
                              accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf"
                              id="fileinput"
                              name="Upload File "
                              :placeholder="$t('fields.uploadfile')"
                              drop-placeholder="Drop file here..."
                              @change="updateValue($event)"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col sm="12">
                    <div class="d-flex float-right mt-1">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        class="mr-1"
                        variant="secondary"
                        @click="$router.go(-1)"
                      >
                        <feather-icon icon="ArrowLeftIcon" class="mr-25" />
                        <span class="align-middle">
                          {{ $t("fields.back") }}</span
                        >
                      </b-button>
                      <b-button
                        v-if="edittype == 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1"
                        :to="{
                          name: GENAPP_APP_STORE_MODULE_NAME + '-view',
                          params: { id: iddata },
                        }"
                      >
                        <feather-icon icon="EyeIcon" class="mr-25" />
                        {{ $t("fields.view") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary                            "
                        class="mr-1"
                        type="submit"
                      >
                        <feather-icon icon="Edit3Icon" class="mr-25" />
                        {{ $t("fields.save") }}
                      </b-button>
                    </div></b-col
                  >
                </b-row>
              </b-form>
              <!--/ form -->
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormDatepicker,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormCheckbox,
  BFormFile,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import inputvselect from "@/views/Component/Input/inputvselect.vue";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import inputselect from "@/views/Component/Input/inputselect.vue";
import inputtext from "@/views/Component/Input/inputtext.vue";
import inputtextarea from "@/views/Component/Input/inputtextarea.vue";
import { required, alphaNum } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import StoreModule from "./StoreModule";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
export default {
  components: {
    BCol,
    BCard,
    BRow,
    BForm,
    AppCollapse,
    AppCollapseItem,
    flatPickr,
    BCardBody,
    BFormGroup,
    inputselect,
    inputvselect,
    BFormRadio,
    inputtextarea,
    inputtext,
    BFormCheckbox,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BButton,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "cashflow-transfer";

    const modulename = "Transfer";
    const modulenamesub = "transfer";
    // Register module
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    let today = new Date();
    let key = 0;
    let edittype = 0;
    let storetype = "addData";
    let iddata = router.currentRoute.params.id;
    if (iddata != undefined) {
      edittype = 1;
      storetype = "editData";
    }
    const blankAppData = {
      name: "",
      transactiontype: "1",
      amount: 0,
      categoryname: "",
      date: "",
      paytype: "",
      paytypedatax: "",
      topaytype: "",
      topaytypedatax: "",
      description: "",
      expencesubserve: "",
      categorynameval: "",
      notes: "",
      selectedmanage: "",
      fileData: {
        name: "",
        file: "",
        size: "",
        type: "",
      },
    };
    const appData = ref(JSON.parse(JSON.stringify(blankAppData)));
    var tempcatch = 1;
    var labelx = ref("");

    const paydatax = ref([]);
    const topaydatax = ref([]);

    const mangepaytype = (val) => {
      appData.value.paytype = val;
      var temp = "";
      if (val && val == "Bank") {
        temp = "fetchbank";
        labelx.value = "bank";
      } else if (val && val == "Cash") {
        temp = "fetchwcash";
        labelx.value = "cash";
      } else if (val && val == "Wallet") {
        temp = "fetchwallets";
        labelx.value = "wallet";
      } else if (val && val == "Paylater") {
        temp = "fetchpaylater";
        labelx.value = "paylater";
      } else if (val && val == "Credit Cards") {
        temp = "fetchcreditcards";
        labelx.value = "creditcards";
      }
      if (temp) {
        paydataxopt(temp);
      }
    };
    var labelto = ref("");

    const mangetopaytype = (val) => {
      appData.value.topaytype = val;
      var temp = "";
      if (val && val == "Bank") {
        temp = "fetchbank";
        labelto.value = "bank";
      } else if (val && val == "Cash") {
        temp = "fetchwcash";
        labelto.value = "cash";
      } else if (val && val == "Wallet") {
        temp = "fetchwallets";
        labelto.value = "wallet";
      } else if (val && val == "Paylater") {
        temp = "fetchpaylater";
        labelto.value = "paylater";
      } else if (val && val == "Credit Cards") {
        temp = "fetchcreditcards";
        labelto.value = "creditcards";
      } else if (val && val == "Loan Accounts") {
        temp = "fetchloanaccounts";
        labelto.value = "loanaccounts";
      }
      if (temp) {
        topaydataxopt(temp);
      }
    };
    const onSubmit = () => {
      if(labelx.value=='creditcards'){
        var flag=0;
        for(var i=0;i<paydatax.value.length;i++){
          if(paydatax.value[i].id==appData.value.paytypedatax){
            flag=1;
            if((paydatax.value[i]['data']['balance'] + appData.value.amount) >= paydatax.value[i]['data']['limit']){
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  text:'Sorry Limit Reached in Credit Cards',
                  variant: "error",
                },
              });
              return ;
            }
          }
        }
        if(!flag){
          return ;
        }
      }
      else if(['cash','bank'].includes(labelx.value)){
        var flag=0;
        for(var i=0;i<paydatax.value.length;i++){
          if(paydatax.value[i].id==appData.value.paytypedatax){
            flag=1;
            if(appData.value.amount >= paydatax.value[i]['data']['balance']){
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  text:'Sorry Limit Reached in Cash',
                  variant: "error",
                },
              });
              return ;
            }
          }
        }
        if(!flag){
          return ;
        }
      }
      let temp = appData.value;
      let formData = new FormData();
      let appdatax = {
        customvalue: temp.relation != "Others" ? 0 : 1,
        name: temp.paytype,
        transactiontype: temp.transactiontype,
        amount: temp.amount,
        date: temp.date,
        description: temp.description,
        paytype: temp.paytype,
        paytypedatax: temp.paytypedatax,
        topaytype: temp.topaytype,
        topaytypedatax: temp.topaytypedatax,
        notes: temp.notes,
      };
      let metadataappdatax = {
        customvalue: temp.relation != "Others" ? 0 : 1,
        amount: temp.amount,
        date: temp.date? temp.date.split('-').reverse().join('-') : null,
        paytypedatax: temp.paytypedatax,
        paytype: temp.paytype,
        name: temp.paytype,
        topaytype: temp.topaytype,
        topaytypedatax: temp.topaytypedatax,
        transactiontype: temp.transactiontype,
      };

      formData.append("name", temp.paytype);
      formData.append("customvalue", metadataappdatax.customvalue);
      formData.append("data", JSON.stringify(appdatax));
      formData.append("metadata", JSON.stringify(metadataappdatax));
      if (edittype == 0) {
        let file = temp.fileData.file;
        formData.append("file", file);
      }
      formData.append("status", 1);
      if (edittype == 1) {
        var jx = {
          id: router.currentRoute.params.id || null,
          customvalue: metadataappdatax.customvalue,
          data: appdatax,
          name: temp.paytype,
          metadata: metadataappdatax,
        };
      }
      if (tempcatch) {
        tempcatch = 0;
        store
          .dispatch(
            GENAPP_APP_STORE_MODULE_NAME + "/" + storetype,
            edittype == 0 ? formData : jx
          )
          .then((response) => {
            if (response.data.success) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  text:
                    modulename +
                    (edittype == 1 ? " editted" : " added") +
                    " successfully",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            tempcatch = 1;
            } else if (response.data.success == 0) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  variant: "error",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            }
            tempcatch = 1;
          })
          .catch((error) => {
            tempcatch = 1;

            if (error.response.status === 404) {
              appData.value = undefined;
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  text: "Couldnt find " + modulenamesub + " details",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          });
      }
    };

    const paydataxopt = (type) => {
      store
        .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/" + type)
        .then((response) => {
          paydatax.value = response.data.data;
        });
    };
    const topaydataxopt = (type) => {
      store
        .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/" + type)
        .then((response) => {
          topaydatax.value = response.data.data;
        });
    };

    if (iddata != undefined) {
      store
        .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchData", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          if (!response.data.data) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            router
              .push({
                name: GENAPP_APP_STORE_MODULE_NAME,
              })
              .catch(() => {});
          }
          appData.value = response.data.data.data;
          appData.value.name = response.data.data.name;
          mangepaytype(appData.value.paytype);
          mangetopaytype(appData.value.topaytype);

          key = Math.random();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            appData.value = undefined;
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 500) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Input fields missing values",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 422) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Please refresh your page",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    }
    const resetappData = () => {
      appData.value = JSON.parse(JSON.stringify(blankAppData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetappData);
    return {
      GENAPP_APP_STORE_MODULE_NAME,
      edittype,
      iddata,
      mangepaytype,
      mangetopaytype,
      appData,
      modulename,
      modulenamesub,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      key,
      today,
      tempcatch,
      paydatax,
      paydataxopt,
      topaydatax,
      topaydataxopt,
      labelx,
      labelto,
    };
  },
  mounted() {},
  methods: {
    subcategorymanage(val) {
      this.appData.subcategory = val;
    },

    managefloat(name, val) {
      this.$set(this.appData, name, parseFloat(val));
    },
    updateValue(value) {
      if (value.target.files[0].size <= 2 * 1048576) {
        this.appData.fileData.file = value.target.files[0];
        this.appData.fileData.name = value.target.files[0].name;
        this.appData.fileData.size = value.target.files[0].size;
        this.appData.fileData.type = value.target.files[0].type;
      } else {
        this.$swal({
          icon: "error",
          title: "File limit Reached!",
          text: "File size must be maximum 2 mb .",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
